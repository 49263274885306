import { Languages } from "@app/types/multilingual"

interface translation {
    CallDriver: string,
    OrderDetails: string,
    PickupAt: string,
    FinishAt: string,
    TrackYourOrder: string,
    CheckTheStatus: string,
    TypeTrackNumber: string,
    TypeNoSpace: string,
    Track: string,
    EditTrackNumber: string,
    orderNotFound: string,
    orderExpired: string
}

export const tStore : {
    [key in  Languages]: translation;
} = {
    en: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    fa: {
        CallDriver: 'تماس با راننده',
        OrderDetails: 'جزییات سفارش',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    de: {
        CallDriver: 'Fahrer anrufen',
        OrderDetails: 'Bestelldetails',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    ckb: {
        CallDriver: 'پەیوەندی بە شۆفێرەوە بکە',
        OrderDetails: 'وردەکارییەکانی داواکاری',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    fr: {
        CallDriver: 'Contacter le coursier',
        OrderDetails: 'Détails de la commande',
        PickupAt: 'Récupéré à',
        FinishAt: 'Terminé à',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    ar: {
        CallDriver: 'اتصل بالسائق',
        OrderDetails: 'تفاصيل الطلب',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    zh: {
        CallDriver: '呼叫司机',
        OrderDetails: '订单详细信息',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    ro: {
        CallDriver: 'Sună curierul',
        OrderDetails: 'Comanda Detalii',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    nl: {
        CallDriver: 'Bel koerier',
        OrderDetails: 'Bestel Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    fi: {
        CallDriver: 'Soita Kuljettajalle',
        OrderDetails: 'Tilauksen tiedot',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    es: {
        CallDriver: 'Llamar al conductor',
        OrderDetails: 'Detalles del pedido',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    it: {
        CallDriver: `Chiama l'autista`,
        OrderDetails: `Dettagli dell'ordine`,
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    az: {
        CallDriver: `Sürücüyə zəng et`,
        OrderDetails: `Sifariş detalları`,
        PickupAt: 'Götürmək',
        FinishAt: 'Bitirmək',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    ru: {
        CallDriver: 'Позвонить водителю',
        OrderDetails: 'Order Details',
        PickupAt: 'Взять',
        FinishAt: 'Завершить',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    tr: {
        CallDriver: `Sürücüyə zəng et`,
        OrderDetails: `Sifariş detalları`,
        PickupAt: 'Götürmək',
        FinishAt: 'Bitirmək',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    pt: {
        CallDriver: 'Ligar para entregador',
        OrderDetails: 'Order Details',
        PickupAt: 'Recolha em',
        FinishAt: 'Acabar em',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    el: {
        CallDriver: 'Κλήση Οδηγού',
        OrderDetails: 'Λεπτομέρειες Παραγγελίας',
        PickupAt: 'Παραλαβή στις',
        FinishAt: 'Ολοκλήρωση σε',
        TrackYourOrder: "Εντοπισμός Διανομής",
        CheckTheStatus: "Ελέγξτε την κατάσταση και την τοποθεσία των παραγγελιών σας",
        TypeTrackNumber: "Πληκτρολογήστε εδώ τον αριθμό παρακολούθησης",
        TypeNoSpace: "Πληκτρολογήστε χωρίς κενό μεταξύ των χαρακτήρων",
        Track: "Εντοπισμός",
        EditTrackNumber: "Επεξεργασία αριθμού παρακολούθησης",
        orderNotFound: "Η παραγγελία δεν υπάρχει",
        orderExpired: "Ο κωδικός παρακολούθησης έχει λήξει "
    },
    'de-AT': {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    ku: {
        CallDriver: 'پەیوەندیکردن لەگەڵ شۆفێر',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    he: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    hi: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    },
    ms: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
        TrackYourOrder: "Track your order",
        CheckTheStatus: "Check the status and location of your orders",
        TypeTrackNumber: "Type your track number here",
        TypeNoSpace: "Type it with no space between characters",
        Track: "Track",
        EditTrackNumber: "Edit track number",
        orderNotFound: "The order doesn’t exist",
        orderExpired: "Tracking number is expired"
    }
}

export const t = (key : keyof translation , language : Languages)=>{
    if(tStore[language] && tStore[language][key]) return tStore[language][key]
    else return tStore['en'][key]
}